import axios from "axios";

export const fetchAllBlogs = async (page, search, category) => {
  var res = await axios.get(
    `blogs??limit=9&page=${page}&search=${search}&category=${category}`
  );
  if (res.status === 200) {
    let articles = [];
    for (let index = 0; index < res.data.blogs.length; index++) {
      let data = res.data.blogs[index];
      articles.push({
        title: data?.title,
        body: data?.body,
        slug: data?.slug,
        coverImage: data?.coverImage,
        modifiedAt: data?.modifiedAt,
      });
    }
    return {
      articles: articles,
      current: res.data?.page,
      totalPage: res.data?.totalPages ?? 1,
    };
  }
};
