import { Col, Container, Row } from "react-bootstrap";
import { FeaturedCard } from "../components/featured_card";
import { Recents } from "../components/recents";
import { Popular } from "../components/popular";
import { MetaTags } from "react-meta-tags";
import { IntroGreeting } from "../components/intro_greeting";
import { useQuery } from "react-query";
import { fetchHome } from "../fetches/home_fetch";
import { LoadingBubble } from "../components/loading_bubble";

export function Home() {
  const { isLoading, data } = useQuery("greeting", fetchHome);

  if (isLoading) {
    return <LoadingBubble />;
  }
  console.log(data);
  return (
    <div className=" p-0">
      <MetaTags>
        <title>Home | Sumi Phuyal</title>
      </MetaTags>
      <IntroGreeting data={data.home} />
      <Container className="px-2 py-5">
        <Row>
          {data.featuredArticle && (
            <Col className="my-2" lg={6} md={12}>
              <FeaturedCard data={data.featuredArticle} />
            </Col>
          )}
          {data.poularArticle && (
            <Col className="my-2" lg={6} md={12}>
              <Popular data={data.poularArticle} />
            </Col>
          )}
        </Row>
        {data.latestArticles?.length !== 0 && (
          <Recents data={data.latestArticles} />
        )}
      </Container>
    </div>
  );
}
