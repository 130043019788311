import React, { useMemo } from "react";
import { useParams } from "react-router";
import { Container, Image } from "react-bootstrap";

import moment from "moment";
import { NoContentFound } from "./no_content_found";
import { ScrollToTop } from "../components/scroll_to_top";
import { ReactSEOMetaTags } from "react-seo-meta-tags";
import MetaTags from "react-meta-tags";

import Helmet from "react-helmet";
import { fetchBlogDetail } from "../fetches/blog_detail_fetch";
import { useQuery } from "react-query";
import { LoadingBubble } from "../components/loading_bubble";
import FadeIn from "react-fade-in/lib/FadeIn";
import { RWebShare } from "react-web-share";
import { Comments, Like } from "react-facebook";

import { ThemeContext } from "../context_api/theme_context.tsx";
import { readingTime } from "reading-time-estimator";
import "../utils/capitalize";
import "../assets/css/article.css";

export function BlogDetail() {
  const { slug } = useParams();
  const { href } = window.location;
  const { isLoading, isError, data } = useQuery(
    slug,
    () => fetchBlogDetail(slug),
    { retry: 1 }
  );
  const stats = useMemo(
    () => readingTime((data?.body ?? "").replace(/(<([^>]+)>)/gi, " ")),
    [data]
  );

  if (isLoading) {
    return <LoadingBubble />;
  }
  if (isError) {
    return <NoContentFound />;
  }
  const url = window.location.href;

  return (
    <ThemeContext.Consumer>
      {({ theme, switchTheme }) => (
        <FadeIn>
          <MetaTags>
            <title>{data.title} | Sumi Phuyal</title>
            <meta
              name="description"
              content={data.body.replace(/(<([^>]+)>)/gi, " ")}
            />
            <meta property="og:title" content={data.title} />
            <meta property="og:image" content={data.coverImage} />
          </MetaTags>
          <ReactSEOMetaTags
            render={(el) => <Helmet>{el}</Helmet>}
            website={{
              url: `https://sumiphuyal.com.np/content/${slug}`,
              title: data.title + " | Sumi Phuyal",
              datePublished: data.createdAt,
              description: data.body,
              language: "en-US",
              image: data.coverImage,
              author: {
                email: "phuyal.sumi12@gmail.com",
                name: "Sumi Phuyal",
              },
              site: {
                siteName: "Sumi Phuyal",
                searchUrl: `https://www.google.com/search?q=${data.title}`,
              },
            }}
            breadcrumb={[
              {
                name: `content/${data.title}`,
                url: "https://sumiphuyal.com.np",
              },
              {
                name: `content/${data.title}`,
                url: `https://sumiphuyal.com.np/content/${slug}`,
              },
            ]}
            blogPost={{
              url: `https://sumiphuyal.com.np/content/${slug}`,
              title: data.title + " | Sumi Phuyal",
              description: data.body.replace(/(<([^>]+)>)/gi, " "),
              image: data.coverImage,
              imageAlt: data.title,
              datePublished: data.createdAt,
              dateModified: data.modifiedAt,
              tags: [
                data.title,
                "Sumi",
                "Sumi Phuyal",
                "Phuyal",
                "Blog",
                "Sumi Blog",
                "Sumi's Blog",
              ],
              author: "Sumi Phuyal",
              site: {
                siteName: "Sumi Phuyal",
              },
            }}
            organization={{
              name: "Sumi Phuyal",
              legalName: "Sumi phuyal",
              url: "https://sumiphuyal.com.np",
              logo: "../static/media/logo.png",
            }}
            twitter={{ twitterUser: "@_beyondstars_" }}
          />

          <div className=" p-0 glass-container">
            <Container>
              <div className="image-container">
                <div
                  className="backdrop-cover-image"
                  style={{
                    backgroundImage: `url("${data.coverImage}")`,
                  }}
                />
                <Image
                  alt={data.title}
                  className="align-self-center img-fluid animate__animated animate__fadeIn cover-image-full"
                  fluid={true}
                  src={data.coverImage}
                />
              </div>
            </Container>
            <Container className="px-5 py-5 article">
              <div className="d-flex flex-row justify-content-end align-items-center">
                <div className=" date-time-text mx-1">
                  {moment(data.modifiedAt).fromNow().capitalize()}
                </div>
                <div className=" mx-1" id="reading-time">
                  ({stats.text})
                </div>
              </div>
              <h1 className="article-detail-title">{data.title}</h1>
              <div className="d-flex flex-row justify-content-between">
                <h3 className="article-by-title">By Sumi Phuyal</h3>
                <RWebShare
                  data={{
                    text: `Here is a article titled "${data.title}". Written by Sumi Phuyal.`,
                    url: url,
                    title: data.title,
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <span className="share-text">Share 🔗</span>
                </RWebShare>
              </div>
              <hr className="articlehr" />
              <div className="ql-body">
                <div dangerouslySetInnerHTML={{ __html: data.body }} />
              </div>
              {/* <div className="social-box"> */}
              <Like
                layout="button_count"
                href={href}
                colorScheme={theme}
                share
                kidDirectedSite={true}
              />
              <Comments
                colorScheme={theme}
                href={`http://www.facebook.com/article=${slug}`}
                lazy
              />
              {/* </div> */}
            </Container>
          </div>
          <ScrollToTop />
        </FadeIn>
      )}
    </ThemeContext.Consumer>
  );
}
