import React from "react";
import Pagination from "react-js-pagination";

type PaginationPropsTye = {
  currentPage: any;
  totalPage: any;
  search: string;
  category: string;
  onPageChange: any;
};

export function ProPagination({
  currentPage,
  totalPage,
  search,
  category,
  onPageChange,
}: PaginationPropsTye) {
  return (
    <Pagination
      linkClass="page-link"
      itemClass="page-item"
      activeLinkClass="page-active"
      activeClass="page-active"
      disabledClass="disabled"
      activePage={currentPage}
      itemsCountPerPage={1}
      totalItemsCount={totalPage}
      pageRangeDisplayed={5}
      onChange={(page) => onPageChange(page)}
      getPageUrl={(page) => {
        let url = `/articles?page=${page}`;
        if (search) {
          url += `&search=${search}`;
        }
        if (category !== "None") {
          url += `&category=${category}`;
        }
        return url;
      }}
    />
  );
}
