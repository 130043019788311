import React, { useEffect } from "react";
import { useState } from "react";
import logo from "./logo.png";
import logodark from "./logo-dark.png";
import "./App.css";
import "./assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Navbar, Nav, Image } from "react-bootstrap";
import {
  Routes,
  Route,
  NavLink,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import { Home } from "./pages/home";
import { BlogDetail } from "./pages/blog_detail_page";
import { BottomFooter } from "./components/bottom_footer.js";
import { SocialLinks } from "./components/social_links.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AllBlogs } from "./pages/all_blogs";
import { NoContentFound } from "./pages/no_content_found";
import axios from "axios";
import "animate.css";

import useLocalStorage from "use-local-storage";
import { ThemeContext } from "./context_api/theme_context.tsx";

import Switch from "react-switch";

import sun from "./assets/icons/sun.png";
import moon from "./assets/icons/moon.png";
import clouds from "./assets/icons/clouds.png";
import stars from "./assets/icons/stars.png";
import { fetchHome } from "./fetches/home_fetch";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { QueryClient, QueryClientProvider } from "react-query";
import { fetchSocialLinks } from "./fetches/fetch_social_link";
import { PrivacyPolicy } from "./pages/privacy_policy";
import { FacebookProvider } from "react-facebook";

const domain =
  //
  "https://sumi.erparasrai.com.np/app/";
// "http://localhost:8000/api/";
axios.defaults.baseURL = domain;
axios.defaults.headers.post["Content-Type"] = "application/json";

const queryClient = new QueryClient();
const prefetchAll = async () => {
  // The results of this query will be cached like a normal query
  await queryClient.prefetchQuery("greeting", fetchHome);
  // await queryClient.prefetchQuery("social-links", fetchSocialLinks);
};

function Navigation() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/content/:slug"
          exact
          id="content"
          element={<BlogDetail />}
        />
        <Route path="/articles/" id="articles" exact element={<AllBlogs />} />
        <Route
          path="/privacy/policy/"
          id="policy"
          element={<PrivacyPolicy />}
        />
        <Route path="*" element={<NoContentFound />} />
      </Routes>
    </>
  );
}

function NavbarBox() {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <ThemeContext.Consumer>
      {({ theme, switchTheme }) => (
        <Navbar
          className={"nav-container sticky-top"}
          expand="md"
          expanded={toggleMenu}
          variant={theme}
        >
          <Container className="py-3" style={{ maxWidth: "normal" }}>
            <NavLink id="logo" to="/">
              <img
                className="image cursor-pointer"
                src={theme === "light" ? logo : logodark}
                height={36}
                width={(408 / 179) * 36}
                alt="logo"
              />
            </NavLink>
            <Navbar.Toggle
              id="nav-btn"
              aria-controls="topNavbar"
              onClick={() => setToggleMenu(!toggleMenu)}
              children={
                toggleMenu ? (
                  <div className="animated">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill={theme === "light" ? "#000000" : "#fff"}
                    >
                      <path
                        d="M11 0.7H13V23.3H11z"
                        transform="rotate(-45.001 12 12)"
                      ></path>
                      <path
                        d="M0.7 11H23.3V13H0.7z"
                        transform="rotate(-45.001 12 12)"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  <div className="animated">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill={theme === "light" ? "#000000" : "#fff"}
                    >
                      <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"></path>
                    </svg>
                  </div>
                )
              }
              onAnimationIteration={() => {}}
            />
            <Navbar.Collapse style={{ flexGrow: 0 }} id="topNavbar">
              <Nav className="me-auto my-sm-2 my-lg-0">
                <NavLink
                  className="nav-link"
                  to="/"
                  onClick={
                    toggleMenu === false
                      ? null
                      : () => setToggleMenu(!toggleMenu)
                  }
                >
                  Home
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="articles"
                  onClick={
                    toggleMenu === false
                      ? null
                      : () => setToggleMenu(!toggleMenu)
                  }
                >
                  Articles
                </NavLink>
                <SocialLinks />
                <div className="align-middle d-flex align-items-center px-2">
                  <Switch
                    onChange={switchTheme}
                    checked={theme === "light"}
                    onColor={"#FFA202"}
                    className="align-middle d-flex align-items-center"
                    activeBoxShadow="0 0 2px 3px #FFA202"
                    offHandleColor="#282828"
                    uncheckedIcon={
                      <center>
                        <Image src={stars} height={20} width={20} alt="stars" />
                      </center>
                    }
                    uncheckedHandleIcon={
                      <center>
                        <Image src={moon} height={20} width={20} alt="moon" />
                      </center>
                    }
                    checkedHandleIcon={
                      <center>
                        <Image src={sun} height={20} width={20} alt="sun" />
                      </center>
                    }
                    checkedIcon={
                      <center>
                        <Image
                          src={clouds}
                          height={20}
                          width={20}
                          alt="clouds"
                        />
                      </center>
                    }
                  />
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    </ThemeContext.Consumer>
  );
}

function App() {
  prefetchAll();
  const firebaseConfig = {
    apiKey: "AIzaSyAyVl73N570EfL-RjicXnqgwbj2m2cYorE",
    authDomain: "sumi-phuyal.firebaseapp.com",
    projectId: "sumi-phuyal",
    storageBucket: "sumi-phuyal.appspot.com",
    messagingSenderId: "512824394216",
    appId: "1:512824394216:web:ac798badffa0ee5fd5a45d",
    measurementId: "G-EDTN010JR5",
  };
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const changeBgColorOfBody = (theme) => {
    let color = "white";
    if (theme === "dark") {
      color = "#252527";
    }
    document.body.style.background = color;
  };
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );
  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    changeBgColorOfBody(newTheme);
    setTheme(newTheme);
  };
  console.log = function () {};

  useEffect(() => {
    changeBgColorOfBody(theme);
  });
  return (
    <FacebookProvider appId="760659952340736">
      <QueryClientProvider client={queryClient}>
        <ThemeContext.Provider
          value={{ theme: theme, switchTheme: switchTheme }}
        >
          <ThemeContext.Consumer>
            {({ theme }) => (
              <div
                className="appbody"
                style={{ height: "100%" }}
                data-theme={theme}
              >
                <Router>
                  <div className="full-screen">
                    <NavbarBox />
                    <div className="cover-full-page">
                      <Navigation />
                    </div>
                    <BottomFooter />
                  </div>
                </Router>
                <ToastContainer />
              </div>
            )}
          </ThemeContext.Consumer>
        </ThemeContext.Provider>
      </QueryClientProvider>
    </FacebookProvider>
  );
}

export default App;
