import axios from "axios";

export const fetchSocialLinks = async () => {
  var response = await axios.get("socials");
  if (response.status === 200) {
    let data = response.data;
    let links = [];
    data.forEach((link) => {
      links.push({
        name: link.socialName,
        url: link.socialLink,
      });
    });
    return links;
  }
};
