import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../assets/css/article.card.css";

export function BlogCard(props) {
  const article = props.article;
  return (
    <Col className="my-2" lg={4} md={6} sm={12}>
      <div className="p-2 article-card">
        <Col style={{ height: 180 }}>
          <div className="article-image-box">
            <Link
              exact
              className="article-card-cover-image"
              to={"/content/" + article?.slug}
              state={article?.body}
              aria-label={`Read more about ${article?.title}`}
            >
              <div
                className="article-backdrop-image"
                style={{
                  backgroundImage: `url("${article?.coverImage}")`,
                }}
              />
              <LazyLoadImage
                alt="Popular blog"
                fluid={true}
                height="180"
                width="100%"
                objectFit="contain"
                sizes="contain"
                className="animate__animated animate__fadeIn article-card-cover-image"
                src={article?.coverImage}
              />
            </Link>
          </div>
        </Col>
        <Col className="py-2">
          <Link
            exact
            to={"/content/" + article?.slug}
            state={article?.content}
            aria-label={`Read more about ${article?.title}`}
            style={{ textDecoration: "none" }}
          >
            <div
              className="article-title"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={article?.title}
            >
              {article?.title}
            </div>
          </Link>
          <div
            className="hiddenContent my-auto"
            style={{ height: 100 }}
            dangerouslySetInnerHTML={{
              __html: article?.body?.replace(/(<([^>]+)>)/gi, " "),
            }}
          ></div>
          <Row className="mt-4">
            <Col className="date-time-text" xs={6}>
              {moment(article?.modifiedAt).fromNow()}
            </Col>
            <Col className=" d-flex justify-content-end" xs={6}>
              <Link
                className="readMore d-inline-flex no-underline-link"
                exact
                to={"/content/" + article?.slug}
                state={article?.body}
                aria-label={`Read more about ${article?.title}`}
              >
                Read more
              </Link>
            </Col>
          </Row>
        </Col>
      </div>
    </Col>
  );
}
