export function PrivacyPolicy() {
  return (
    <>
      <body cz-shortcut-listen="true">
        <h2>Privacy Policy</h2>
        <p>We build this website as a personal blog posting site.</p>{" "}
        <p>
          This page uses facebook comment plugin to comment any articles posted.
          This app has not intension of sharing data with 3rd parties.
        </p>{" "}
        <p>
          <strong>Contact Us</strong>
        </p>{" "}
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at erparasrai@gmail.com.
        </p>{" "}
        <p></p>
      </body>
    </>
  );
}
