import axios from "axios";

export const fetchCategories = async () => {
  var res = await axios.get("categories/");
  if (res.status === 200) {
    var data = res.data;
    return data;
  } else if (res.status === 404) {
    throw new Error("Not found");
  } else {
    throw new Error("Something went wrong!");
  }
};
