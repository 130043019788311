import moment from "moment";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import FadeIn from "react-fade-in/lib/FadeIn";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
export const PopularFeaturedCard = ({ data, type }) => {
  return (
    <FadeIn>
      <Card className="featured-card p-2">
        <Row>
          <Col
            className="px-3 py-2 animate__animated animate__fadeIn"
            lg={6}
            md={6}
            sm={12}
            style={{
              display: "flex",
              height: 260,
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Link
              className="no-underline-link"
              to={"content/" + data?.slug}
              aria-label={`Read more about ${data?.title}`}
            >
              <span
                className="featured-title"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={data?.title}
              >
                {data?.title}
              </span>
            </Link>
            <section>
              <span className="info-pill">{type ?? "Featured"}</span>
            </section>
            <div
              className="body-view"
              dangerouslySetInnerHTML={{
                __html: data?.body?.replace(/(<([^>]+)>)/gi, " "),
              }}
              safe={true}
            />
            <Row className="mt-4">
              <Col className="date-time-text" xs={6}>
                {moment(data?.modifiedAt).fromNow()}
              </Col>
              <Col className=" d-flex justify-content-end" xs={6}>
                <Link
                  className="readMore d-inline-flex no-underline-link"
                  to={"content/" + data?.slug}
                  aria-label={`Read more about ${data?.title}`}
                >
                  Read more
                </Link>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Container className="featured-image-box">
              <Link
                className="no-underline-link featured-card-cover-image"
                to={"content/" + data?.slug}
                aria-label={`Read more about ${data?.title}`}
              >
                <link rel="preload" as="image" href={data?.coverImage} />
                <div
                  className="featured-card-backdrop-cover-image"
                  style={{ backgroundImage: `url("${data?.coverImage}")` }}
                />
                <LazyLoadImage
                  fluid
                  className="animate__animated animate__fadeIn featured-card-cover-image"
                  alt="Cover Image"
                  sizes="cover"
                  src={data?.coverImage}
                  onError={(e) => {
                    e.target.onerror = null; // prevents looping
                    e.target.src =
                      "https://images.unsplash.com/photo-1554415707-6e8cfc93fe23?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8d29ya3xlbnwwfHwwfHw%3D&w=1000&q=80";
                  }}
                />
              </Link>
            </Container>
          </Col>
        </Row>
      </Card>
    </FadeIn>
  );
};
