import { Row } from "react-bootstrap";
import { BlogCard } from "./blog_card";
import { fetchLatest } from "../fetches/home_fetch";
import { useQuery } from "react-query";
import FadeIn from "react-fade-in/lib/FadeIn";
export function Recents({ data }) {
  return (
    <FadeIn className="pt-4">
      <span id="recent-title">Recents</span>
      <Row>
        {data.map((article) => (
          <BlogCard article={article} />
        ))}
      </Row>
    </FadeIn>
  );
}
