import { useQuery } from "react-query";
import { fetchSocialLinks } from "../fetches/fetch_social_link";

const icons = {
  facebook: (
    <svg
      className="socialLogin"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <path d="M12,2C6.477,2,2,6.477,2,12c0,5.013,3.693,9.153,8.505,9.876V14.65H8.031v-2.629h2.474v-1.749 c0-2.896,1.411-4.167,3.818-4.167c1.153,0,1.762,0.085,2.051,0.124v2.294h-1.642c-1.022,0-1.379,0.969-1.379,2.061v1.437h2.995 l-0.406,2.629h-2.588v7.247C18.235,21.236,22,17.062,22,12C22,6.477,17.523,2,12,2z"></path>
    </svg>
  ),
  instragram: (
    <svg
      className="socialLogin"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C12.717 0 13.056 0.00999994 14.122 0.0599999C15.187 0.11 15.912 0.277 16.55 0.525C17.21 0.779 17.766 1.123 18.322 1.678C18.8305 2.1779 19.224 2.78259 19.475 3.45C19.722 4.087 19.89 4.813 19.94 5.878C19.987 6.944 20 7.283 20 10C20 12.717 19.99 13.056 19.94 14.122C19.89 15.187 19.722 15.912 19.475 16.55C19.2247 17.2178 18.8311 17.8226 18.322 18.322C17.822 18.8303 17.2173 19.2238 16.55 19.475C15.913 19.722 15.187 19.89 14.122 19.94C13.056 19.987 12.717 20 10 20C7.283 20 6.944 19.99 5.878 19.94C4.813 19.89 4.088 19.722 3.45 19.475C2.78233 19.2245 2.17753 18.8309 1.678 18.322C1.16941 17.8222 0.775931 17.2175 0.525 16.55C0.277 15.913 0.11 15.187 0.0599999 14.122C0.0129999 13.056 0 12.717 0 10C0 7.283 0.00999994 6.944 0.0599999 5.878C0.11 4.812 0.277 4.088 0.525 3.45C0.775236 2.78218 1.1688 2.17732 1.678 1.678C2.17767 1.16923 2.78243 0.775729 3.45 0.525C4.088 0.277 4.812 0.11 5.878 0.0599999C6.944 0.0129999 7.283 0 10 0ZM10 5C8.67392 5 7.40215 5.52678 6.46447 6.46447C5.52678 7.40215 5 8.67392 5 10C5 11.3261 5.52678 12.5979 6.46447 13.5355C7.40215 14.4732 8.67392 15 10 15C11.3261 15 12.5979 14.4732 13.5355 13.5355C14.4732 12.5979 15 11.3261 15 10C15 8.67392 14.4732 7.40215 13.5355 6.46447C12.5979 5.52678 11.3261 5 10 5ZM16.5 4.75C16.5 4.41848 16.3683 4.10054 16.1339 3.86612C15.8995 3.6317 15.5815 3.5 15.25 3.5C14.9185 3.5 14.6005 3.6317 14.3661 3.86612C14.1317 4.10054 14 4.41848 14 4.75C14 5.08152 14.1317 5.39946 14.3661 5.63388C14.6005 5.8683 14.9185 6 15.25 6C15.5815 6 15.8995 5.8683 16.1339 5.63388C16.3683 5.39946 16.5 5.08152 16.5 4.75ZM10 7C10.7956 7 11.5587 7.31607 12.1213 7.87868C12.6839 8.44129 13 9.20435 13 10C13 10.7956 12.6839 11.5587 12.1213 12.1213C11.5587 12.6839 10.7956 13 10 13C9.20435 13 8.44129 12.6839 7.87868 12.1213C7.31607 11.5587 7 10.7956 7 10C7 9.20435 7.31607 8.44129 7.87868 7.87868C8.44129 7.31607 9.20435 7 10 7Z"
        fill="#454444"
      />
    </svg>
  ),
  twitter: (
    <svg
      className="socialLogin"
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1623 2.6569C20.3989 2.9946 19.5893 3.21638 18.7603 3.3149C19.634 2.79234 20.288 1.96992 20.6003 1.0009C19.7803 1.4889 18.8813 1.8309 17.9443 2.0159C17.3149 1.34248 16.4807 0.895866 15.5713 0.745488C14.6618 0.595109 13.7282 0.749394 12.9156 1.18436C12.1029 1.61932 11.4567 2.31059 11.0774 3.1507C10.6981 3.9908 10.607 4.93268 10.8183 5.8299C9.1554 5.74656 7.52863 5.31443 6.04358 4.56157C4.55854 3.80871 3.24842 2.75195 2.1983 1.4599C1.82659 2.09835 1.63125 2.82413 1.6323 3.5629C1.6323 5.0129 2.3703 6.2939 3.4923 7.0439C2.82831 7.023 2.17893 6.84368 1.5983 6.5209V6.5729C1.5985 7.5386 1.93267 8.47452 2.54414 9.22196C3.15562 9.96941 4.00678 10.4824 4.9533 10.6739C4.33691 10.8409 3.6906 10.8656 3.0633 10.7459C3.33016 11.5771 3.8503 12.3041 4.55089 12.825C5.25147 13.3459 6.09743 13.6347 6.9703 13.6509C6.10278 14.3322 5.10947 14.8359 4.04718 15.1331C2.98488 15.4303 1.87442 15.5152 0.779297 15.3829C2.69099 16.6123 4.91639 17.265 7.1893 17.2629C14.8823 17.2629 19.0893 10.8899 19.0893 5.3629C19.0893 5.1829 19.0843 5.0009 19.0763 4.8229C19.8952 4.23107 20.6019 3.49792 21.1633 2.6579L21.1623 2.6569Z"
        fill="#454444"
      />
    </svg>
  ),
  linkedin: (
    <svg
      className="socialLogin"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.335 15.339H12.67V11.162C12.67 10.166 12.65 8.884 11.28 8.884C9.891 8.884 9.679 9.968 9.679 11.089V15.339H7.013V6.75H9.573V7.92H9.608C9.966 7.246 10.836 6.533 12.136 6.533C14.836 6.533 15.336 8.311 15.336 10.624V15.339H15.335ZM4.003 5.575C3.79956 5.57526 3.59806 5.53537 3.41006 5.45761C3.22207 5.37984 3.05127 5.26574 2.90746 5.12184C2.76365 4.97793 2.64965 4.80706 2.57201 4.61901C2.49437 4.43097 2.4546 4.22944 2.455 4.026C2.4552 3.71983 2.54618 3.4206 2.71644 3.16615C2.8867 2.91169 3.12859 2.71343 3.41153 2.59645C3.69447 2.47947 4.00574 2.44902 4.30598 2.50894C4.60622 2.56886 4.88196 2.71648 5.09831 2.93311C5.31466 3.14974 5.46191 3.42566 5.52145 3.72598C5.58099 4.0263 5.55013 4.33753 5.43278 4.62032C5.31543 4.9031 5.11687 5.14474 4.86219 5.31467C4.60751 5.4846 4.30817 5.5752 4.002 5.575H4.003ZM5.339 15.339H2.666V6.75H5.34V15.339H5.339ZM16.67 0H1.329C0.593 0 0 0.58 0 1.297V16.703C0 17.42 0.594 18 1.328 18H16.666C17.4 18 18 17.42 18 16.703V1.297C18 0.58 17.4 0 16.666 0H16.669H16.67Z"
        fill="#454444"
      />
    </svg>
  ),
};

export function SocialLinks() {
  const { isLoading, data } = useQuery("social-links", fetchSocialLinks);

  if (isLoading) return <></>;
  return (
    <div className="d-flex align-items-center flex-row justify-content-center">
      {data?.reverse().map((link) => {
        return (
          <a
            className="d-inline-flex m-2 mx-3"
            href={link.url}
            target="_blank"
            rel="noreferrer"
            aria-label={link.name}
          >
            {icons[link.name]}
          </a>
        );
      })}
    </div>
  );
}
