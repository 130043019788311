import { Col, Container, Row, Image } from "react-bootstrap";
import { ReactSEOMetaTags } from "react-seo-meta-tags";
import FadeIn from "react-fade-in/lib/FadeIn";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../logo.png";
import "../assets/css/intro.css";

export function IntroGreeting({ data }) {
  return (
    <FadeIn>
      <ReactSEOMetaTags
        website={{
          url: `https://sumiphuyal.com.np/`,
          datePublished: "2022",
          description: data?.biodata,
          language: "en-US",
          image: data?.coverImage,
          author: {
            email: "phuyal.sumi12@gmail.com",
            name: "Sumi Phuyal",
          },
          site: {
            siteName: "Sumi Phuyal",
            searchUrl: `https://www.google.com/search?q=Sumi Phuyal`,
          },
        }}
        breadcrumb={[
          { name: "Sumi Site", url: `https://sumiphuyal.com.np/` },
          { name: "Sakhi", url: `https://sumiphuyal.com.np/` },
          { name: "Sumi", url: `https://sumiphuyal.com.np/` },
          { name: "Blog", url: `https://sumiphuyal.com.np/` },
          { name: "Sumi Phuyal", url: `https://sumiphuyal.com.np/` },
        ]}
        organization={{
          name: "Sumi Phuyal",
          legalName: "Sumi Phuyal",
          url: `https://sumiphuyal.com.np/`,
          logo: `https://sumiphuyal.com.np${logo}`,
        }}
        blogPost={{
          title: "Sumi Phyal",
          url: `https://sumiphuyal.com.np/`,
          image: data?.coverImage,
          tags: [
            "Sumi",
            "Sumi Phuyal",
            "Sumi Blog",
            "Nurse",
            "Physcologist",
            "Writer",
          ],
        }}
      />
      <Container className={"introbox"}>
        <Row>
          <Col
            className="d-flex justify-content-center justify-content-lg-end py-0"
            sm={12}
            md={12}
            lg={6}
          >
            <div
              className="d-inline-flex flex-column justify-content-center align-self-center mx-sm-0 mx-md-4"
              style={{ maxWidth: 450 }}
            >
              <h1 className={"greeting-text d-inline-flex text-bold"}>
                {data?.greeting}
              </h1>
              <h3 className="d-inline-flex">{data?.biodata}</h3>
            </div>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={6}
            className="d-flex justify-content-xs-center justify-content-md-center justify-content-ld-start py-2"
          >
            <div className="cover-image-box">
              <link rel="preload" as="image" href={data?.coverImage} />
              <LazyLoadImage
                className="display-image fluid animate__animated animate__fadeIn"
                src={data?.coverImage}
                alt="Sumi phuyal's Greeting"
                placeholderSrc={data?.coverImage}
                fluid={true}
                onProgress={
                  <Image
                    className="display-image"
                    style={{ filter: "blur(0.625rem)" }}
                    src={data?.coverImageLoading}
                    fluid={true}
                  />
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
    </FadeIn>
  );
}
