import { Image } from "react-bootstrap";
import STP from "react-scroll-to-top";

export function ScrollToTop() {
    return (
        <STP smooth height={300} width={300} style={{ backgroundColor: "#FFA100", width: 60, height: 60, borderRadius: 60, textAlign: "center" }}
            color="#FFA100"
            component={<Image
                    width={40}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAFDElEQVR4nO2a3YtVVRjGn1eYyXGSJJ3GgTwk0ViN0E2NH5MQBhaMZQZBYTeRhDkRQsFkaTfRZRH+AeVFXTQWBZZ0EVoSWTclfs70gaMTo2Cj1cw4Iuavi70nTsd91lpnf8ypPD+YizNrred53/esvfbaax+pQYMGDRo0aHCtYvUyBloktcYfJ81sqh5xzEgBgKWSVkvqkbQk/ptd0W1K0g+ShiR9JWmvmR2difgKAWgHXgaOk55jwFbgpnrnE0yc+A7gQobEK5kE3vpXFwKYBfQB53NMvJJzwLNA3dauRIAFwJ4CE6/kc6C93nlLkoAu4NQMJj/NSeDOeie/DBirQ/LTjAHdWXJIfS0BXZL2S7oxcMi4pE8k7ZN0UNKwpN/itnmSFku6S9HtslfS3EDdMUmrzOx4YP/sAG2ET/tRYBMwpwb9OcBG4MdAj2FgfpE5lwc3C/gsIKiLwHag1a9a1asJ6I+1fHzKTNwdiG51Pk4DK3L0XAmcCfDdlJdntUDa8d/nDwOLCvAuxdouxoC2vL3Lg9jhCeBMEcmX+Zfwz4Q3ijJvJ9qSVmOKHKe9I46VuNeESYrYMhM92LjYnrtp9Vi2emLpL8J00GE4SobVPkUszcDPjniO5G241FPxYlff5Jie8cR0e55mzzuMxoHrczMLj6k19q7G5hCdWYF+PY623WY2EaiTG2Y2KWmPo8uqEJ3QArim075AjSLY62gLugRCC3Cbo+1goEYRuLxdMf+NtwBEp7ctji4nQowKwuXdClQevF5FyAzwPZb+EaBxFcAa4FvgG2BNGg1Jv3vaQx+pq0O0A3Thmh3VNB8FLpVpXAYeT6HT6okt+44QmOsxqWnvD6yvSH6aS8D6GrVKntiyb84Aw/0MsKwGrUeqJF9ehHU16C13aI2HaHjXADND7sUmqADAw5Lel9Tk6NYkaSDuG4LLO2hxDr0NHna03esbDDwkaZek5gCvZkm7gLUBfV3ehwLGhwFscUy1CeAGx9hewo60KrkI9Dp05+G+NJ/LswB3eIJNfBgCbiXbK7JJYHEVbd/RXGduBYgNhxxmJ0jYdJD8xDYMHEj4/4G4rZKNCbotRC9GqhF8RB66BkjSTkfbLZJeSPj/d5KulH0eUXTuP5LQN6ntiqTvE/q+KKnkiOcdR1s6gIVEx17VmALuSRi3AfgSeJd4zwAMJIwfiNtKwHvAF8ATCXrd+I/EijkYxX8oepKAl5auAnjGLcT/QubNfLJNDmA+/lPZQeBmj07NBQA6gCMe77PU+O3XsgbIzMYkbfF0WyJpP3B3LdouiC6tryV1ebr2mdnZvHxdAX3s+SYg2ta+AlyXMD5oBgCzgW24t8/TfFR44mWBteG+LZZzCthQawGAJ4GRQI9BYEGaXGq6BKaJp9n9Sr6dVbJIUl8Km82SnGtJzKikB83s1xQe6QogSWb2i6S1ks6n1ciBc5IeMLPhtAKpCyBJZnZIUrekfF9EhDEkqcfMMnlnKoAkmdlPklZI+jCrVg3sltRtZoNZhTIXQJLi9wKPSXpa0uk8NKswKukpSevMLNVZZCW5FECKDk7M7G1JnZJeV/TT17y4IOk1SZ1mtjM+pMmF3AowjZlNmNk2RQ8rL0nK8uOlY5L6JZXM7NX4bdB/D6Cj4nPIPqBDM0DuMyAJM6tcF5Lu2f/YwiaM+f9A9CuPP8u+/cvA8nrHNaMAq4EP4r/76h1PgwYNGjS4FvkL8bd1ug90DxUAAAAASUVORK5CYII="
                />}
        />);
}