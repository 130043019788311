import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SocialLinks } from "./social_links";
import "../assets/css/footer.css";

export const BottomFooter = () => {
  return (
    <footer>
      <Container className="px-2 py-0">
        <Row>
          <Col
            sm={12}
            md={12}
            lg={4}
            className="website-information py-2 d-flex align-items-center justify-content-lg-start justify-content-center"
          >
            <b className="text-bolder">Sumiverse </b>&nbsp;
            {new Date().getFullYear()} copyright all rights reserved
          </Col>
          <Col
            sm={12}
            md={12}
            lg={4}
            className="center-information py-2  text-center align-items-center justify-content-center justify-content-center"
          >
            <div className="d-flex text-center align-items-center justify-content-center justify-content-center">
              Designed By &nbsp;
              <a
                className="center-hightlight-information"
                href="https://suzatakhadka.com.np"
                target="_blank"
                rel="noreferrer"
              >
                Sujata Khadka
              </a>
            </div>

            <div className="d-flex text-center align-items-center justify-content-center justify-content-center">
              Developed By &nbsp;
              <a
                className="center-hightlight-information"
                href="https://erparasrai.com.np"
                target="_blank"
                rel="noreferrer"
              >
                Paras Rai
              </a>
            </div>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={4}
            className="d-flex justify-content-lg-end  justify-content-center "
          >
            <SocialLinks />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
