import axios from "axios";

export const fetchHome = async () => {
  var res = await axios.get("home");
  if (res.status === 200) {
    return {
      home: {
        greeting: res.data.greeting.greeting,
        coverImage: res.data.greeting.coverImage,
        about: res.data.greeting.about,
        biodata: res.data.greeting.bioData,
      },
      featuredArticle: res.data.featured,
      poularArticle: res.data.popular,
      latestArticles: res.data.recents,
    };
  }
};
