import axios from "axios";

export const fetchBlogDetail = async (slug) => {
  var res = await axios.get(`blog/?slug=${slug}`, {
    validateStatus: false,
  });

  if (res.status === 200) {
    var data = res.data;
    axios.put(`blog/?id=${data?.id}`);
    return {
      coverImage: data?.coverImage,
      title: data?.title,
      body: data?.body,
      visits: data?.visits,
      modifiedAt: data.modifiedAt,
      isFeatured: data.isFeatured,
    };
  } else if (res.status === 404) {
    throw new Error("Not found");
  } else {
    throw new Error("Something went wrong!");
  }
};
