import Lottie from "lottie-react";
import { MetaTags } from "react-meta-tags";
import backgroundAnimation from "../assets/animations/404.json";
import "../assets/css/no.content.css";

export function NoContentFound({ title }) {
  return (
    <div className="no-content-box animate__animated animate__fadeIn">
      <MetaTags>
        <title>404 | Sumi Phuyal</title>
      </MetaTags>
      <Lottie
        loop={true}
        animationData={backgroundAnimation}
        className="lottie-height"
      />
      <span className="no-content-title">
        {title ?? "The page is beyond the universe"}
      </span>
      <br />
      <span className="not-found-text">
        {title ? "" : "(404 Page not found)"}
      </span>
    </div>
  );
}
