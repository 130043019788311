import {
  Button,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BlogCard } from "../components/blog_card";
import { ScrollToTop } from "../components/scroll_to_top";
import { MetaTags } from "react-meta-tags";
import { fetchAllBlogs } from "../fetches/all_blog_fetch";
import { useQuery } from "react-query";
import { LoadingBubble } from "../components/loading_bubble";
import FadeIn from "react-fade-in/lib/FadeIn";
import { fetchCategories } from "../fetches/all_categories_fetch";
import searchIcon from "../assets/icons/search.png";
import searchDarkIcon from "../assets/icons/search-dark.png";

import { ThemeContext } from "../context_api/theme_context.tsx";
import { useEffect, useState } from "react";
import { NoContentFound } from "./no_content_found";
import { ProPagination } from "../components/pagination.tsx";

export function AllBlogs() {
  // States

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ?? 1;
  const [searchKey, setSearch] = useState(searchParams.get("search") ?? "");
  const [category, setCategory] = useState(
    searchParams.get("category") ?? "None"
  );

  // React query
  const categories = useQuery("all-categories", () => fetchCategories());
  const { isLoading, data, refetch } = useQuery(
    `all-articles-${page}-${category}`,
    () => fetchAllBlogs(page, searchKey, category)
  );

  // Functions

  const handleFormSubmit = (categoryy) => {
    categoryy = categoryy ?? category;
    let urls = [];
    if (page) {
      urls.push(`page=1`);
    }
    if (searchKey) {
      urls.push(`search=${searchKey}`);
    }
    if (categoryy !== "None") {
      urls.push(`category=${categoryy}`);
    }
    if (urls.length > 0) {
      navigate(`/articles?${urls.join("&")}`);
    } else {
      navigate(`/articles`);
    }
  };

  useEffect(() => {
    setSearch(searchParams.get("search") ?? "");
    setCategory(searchParams.get("category") ?? "None");
  }, [searchParams]);

  // Render
  if (isLoading) {
    return <LoadingBubble />;
  }

  return (
    <>
      <FadeIn>
        <Container className="py-3">
          {/* Search and filter  */}
          {/* <div class="position-static d-flex justify-content-end w-100">
            <InputGroup
              className="mb-3"
              style={{ width: 300, marginRight: 20 }}
            >
              <Form.Control
                className="search-box"
                name="search"
                placeholder="Search....."
                aria-label="Search"
                value={searchKey}
                onSubmit={() => handleFormSubmit()}
                onChange={(event) => setSearch(event.target.value)}
                aria-describedby="basic-search"
              />

              <Button
                variant="outline-success"
                id="button-search"
                onClick={() => {
                  handleFormSubmit();
                  refetch();
                }}
              >
                <ThemeContext.Consumer>
                  {({ theme, switchTheme }) => (
                    <Image
                      src={theme === "light" ? searchIcon : searchDarkIcon}
                      height={18}
                    />
                  )}
                </ThemeContext.Consumer>
              </Button>
            </InputGroup>
            <InputGroup className="mb-3" style={{ width: 300 }}>
              <InputGroup.Text className="category-info" id="category">
                Category
              </InputGroup.Text>
              <Form.Select
                placeholder="Select Category"
                name="category"
                className="category-box"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  handleFormSubmit(e.target.value);
                }}
              >
                <option>None</option>
                {categories.isLoading ? (
                  <></>
                ) : (
                  categories.data.map((category) => {
                    return (
                      <option value={category.name}>{category.name}</option>
                    );
                  })
                )}
              </Form.Select>
            </InputGroup>
          </div> */}
          <ScrollToTop />
          <div>
            {/* Body */}
            <Row>
              {data?.articles.length === 0 ? (
                <NoContentFound title="Articles not found" />
              ) : (
                <>
                  <MetaTags>
                    <title>Articles | Sumi Phuyal</title>
                  </MetaTags>
                </>
              )}
              {data?.articles.map((article, index) => (
                <BlogCard article={article} />
              ))}
            </Row>

            {/* Pagination */}
            {data?.articles?.length === 0 ? (
              <></>
            ) : (
              <div aria-label="Page navigation" className="paginator my-5">
                <ProPagination
                  currentPage={page - 0}
                  totalPage={data.totalPage}
                  search={searchKey}
                  category={category}
                  onPageChange={(page) => {
                    let urls = [];
                    if (page) {
                      urls.push(`page=${page}`);
                    }
                    if (searchKey) {
                      urls.push(`search=${searchKey}`);
                    }
                    if (category !== "None") {
                      urls.push(`category=${category}`);
                    }
                    navigate(`/articles?${urls.join("&")}`);
                  }}
                />
              </div>
            )}
          </div>
        </Container>
      </FadeIn>
    </>
  );
}
